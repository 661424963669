import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "score": 1,
  "featured": false,
  "tileImage": "./tile-image-01.png",
  "tileTextColorScheme": "light",
  "types": ["research"],
  "status": ["completed"],
  "title": "Hacking Rejsekortet",
  "subtitle": "Penetration testing of Rejsekortet.",
  "description": "",
  "years": ["2016"],
  "institutions": ["dtu compute"],
  "location": "lyngby",
  "keywords": {
    "topics": ["security", "penetration testing"],
    "tools": ["android", "rfid"]
  },
  "people": [{
    "name": "Niklas Buhl",
    "role": "Developer"
  }, {
    "name": "Jesper Kirial",
    "role": "Developer"
  }, {
    "name": "Taras Karpin",
    "role": "Developer"
  }]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`DISCLAIMER: We did not actually hack it, we only found weaknesses in Rejsekortet. Security testing and finding point of entry for possible hack of Rejsekortet.`}</p>
    <p>{`This project was a part of a security course at DTU.`}</p>
    <p>{`How secure is the RFID technology?`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      